import { render, staticRenderFns } from "./UnderConstructionLoader.vue?vue&type=template&id=857e245a&scoped=true&"
var script = {}
import style0 from "./UnderConstructionLoader.vue?vue&type=style&index=0&id=857e245a&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "857e245a",
  null
  
)

export default component.exports